export default {
  rows: [],
  columns: [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
    },
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
    },
    // {
    //   title: 'Replace to',
    //   dataIndex: 'replace',
    //   key: 'replace',
    // },
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      scopedSlots: { customRender: 'is_active' },
      width: '15%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
      width: '15%',
    },
  ],
  loading: false,
  pagination: {
    current: 1,
    pageSize: 10,
    total: -1,
  },
}
