<template>
  <div class="chat-blacklist__table-wrapper">
    <ChatBLTableActions @openModalAddWord="openWordModal(null)" @changeInputValue="searchWords"/>
    <a-table
      :columns="table.columns"
      :dataSource="table.rows"
      :row-key="record => record.id"
      :loading="table.loading"
      @change="handleTableChange"
    >
      <template v-slot:is_active="id, record">
        <a-switch :loading="record.loading" @change="changeWordActive(record)" :checked="record.is_active"/>
      </template>
      <template v-slot:actions="id, record">
        <div class="chat-blacklist__actions" :class="{'disabled': record.loading}">
          <div class="chat-blacklist__action" @click="openWordModal(record)">
            <i class="fe fe-edit text-muted"/>
          </div>
          <div class="chat-blacklist__action">
            <a-popconfirm
              :title="`${$t('chatBlacklist.removeConfirm')}`"
              @confirm="deleteWordRequest(record)"
            >
              <i class="fe fe-trash-2 text-danger"/>
            </a-popconfirm>
          </div>
        </div>
      </template>
    </a-table>
    <ChatBLTableWordModal
      ref="chat-blacklist__add-modal"
      @eventAddWordValue="addWord"
      @eventUpdateWordValue="updateWordValue"
    />
  </div>
</template>

<script>
import tableData from './ChatBLTableData.js'
import apiClient from '@/services/axios'
import ChatBLTableActions from '@/views/chat-blacklist/table/ChatBLTableActions.vue'
import ChatBLTableWordModal from '@/views/chat-blacklist/ChatBLTableWordModal.vue'

export default {
  components: { ChatBLTableWordModal, ChatBLTableActions },
  data() {
    return {
      sourceData: [],
      searchValue: '',
      table: tableData,
      searchDebounce: null,
    }
  },
  async created() {
    await this.fetchChatBlacklist()
  },
  methods: {
    async deleteWordRequest(record) {
      try {
        record.loading = true
        await apiClient.delete(`/admin/chat/bl/${record.id}`)
        await this.removeWord(record)
        this.$notification.success({
          message: 'Success',
          description: '',
        })
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        record.loading = false
      }
    },
    removeWord(wordData) {
      const idx = this.sourceData.findIndex(el => el.id === wordData.id)
      if (idx !== -1) {
        this.sourceData = [
          ...this.sourceData.slice(0, idx),
          ...this.sourceData.slice(idx + 1),
        ]
        this.filterTableRows()
      }
    },
    updateWordValue(wordData) {
      this.sourceData.find(el => el.id === wordData.id).word = wordData.word
      this.filterTableRows()
    },
    addWord(wordData) {
      wordData.loading = false
      wordData.is_active = true
      this.sourceData = [wordData, ...this.sourceData]
      this.filterTableRows()
    },
    filterTableRows() {
      const regex = new RegExp(`.*${this.searchValue}.*`, 'gm')
      this.table.rows = this.sourceData.filter(el => {
        return el.word.match(regex)
      })
    },
    searchWords(e) {
      this.searchValue = e.target.value
      this.table.loading = true
      clearTimeout(this.searchDebounce)
      this.searchDebounce = setTimeout(() => {
        this.filterTableRows()
        this.table.loading = false
      }, 314)
    },
    openWordModal(record) {
      if (record) {
        this.$refs['chat-blacklist__add-modal'].setData(record)
      }
      this.$refs['chat-blacklist__add-modal'].isOpened = true
    },
    handleTableChange() {
      console.log('handleTableChange')
    },
    async changeWordActive(record) {
      try {
        record.loading = true
        const response = await apiClient.patch(`/admin/chat/bl/${record.id}/active/${Number(!record.is_active)}`)
        this.table.rows.find(el => el.id === response.data.data.id).is_active = response.data.data.is_active
        this.$notification.success({
          message: 'Success',
          description: '',
        })
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        record.loading = false
      }
    },
    async fetchChatBlacklist() {
      try {
        this.table.loading = true
        const response = await apiClient.get('/admin/chat/bl')
        this.table.rows = response.data.data.map(el => {
          el.loading = false
          return el
        })
        this.table.rows.sort((el1, el2) => {
          return el1.id > el2.id ? -1 : 1
        })
        this.sourceData = [...this.table.rows]
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        this.table.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.chat-blacklist__table-wrapper {

}

</style>
