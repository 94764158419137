<template>
  <a-modal
    v-model="isOpened"
    :title="formData.id === null ? $t('chatBlacklist.addWordModal.addTitle') : $t('chatBlacklist.addWordModal.updateTitle')"
    class="chat-blacklist__add-modal"
    width="600px"
    :afterClose="closeEvent"
    :confirmLoading="formLoading"
    @ok="formSubmit"
  >
    <a-form-model
      ref="form"
      :model="formData"
      :rules="formRules"
      @submit="formSubmit"
    >
      <a-row>
        <a-col>
          <a-form-model-item ref="word" prop="word" label="Word">
            <a-input v-model="formData.word" placeholder="Enter a word here">
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'
import apiClient from '@/services/axios'

export default defineComponent({
  data() {
    return {
      isOpened: false,
      formData: {
        id: null,
        word: '',
        replace: '',
      },
      formLoading: false,
      formRules: {
        word: [{ required: true, message: 'Please input word', trigger: 'change' }],
      },
    }
  },
  methods: {
    setData(wordData) {
      this.formData.id = wordData.id
      this.formData.word = wordData.word
      this.formData.replace = wordData.replace
    },
    async formSubmit(e) {
      e.preventDefault()
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.formData.id) {
            await this.addWordRequest()
          } else {
            await this.updateWordRequest()
          }
        }
      })
    },
    async updateWordRequest() {
      try {
        this.formLoading = true
        const response = await apiClient.patch(`/admin/chat/bl/${this.formData.id}`, {
          word: this.formData.word,
          replace: this.formData.replace,
        })
        this.isOpened = false
        this.closeEvent()
        this.$notification.success({
          message: 'Success',
          description: '',
        })
        this.$emit('eventUpdateWordValue', response.data.data)
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        this.formLoading = false
      }
    },
    async addWordRequest() {
      try {
        this.formLoading = true
        const response = await apiClient.post('/admin/chat/bl', {
          word: this.formData.word,
          replace: this.formData.replace,
        })
        this.isOpened = false
        this.closeEvent()
        this.$notification.success({
          message: 'Success',
          description: '',
        })
        this.$emit('eventAddWordValue', response.data.data)
      } catch (e) {
        this.$notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } finally {
        this.formLoading = false
      }
    },
    closeEvent() {
      this.formData.word = ''
      this.formData.replace = ''
      this.formData.id = null
      this.formLoading = false
    },
  },
})
</script>
