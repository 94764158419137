<template>
  <div class="chat-blacklist">
    <a-card class="mt-2">
      <ChatBLCardHeader/>
      <ChatBLTable/>
    </a-card>
  </div>
</template>

<script>

import { defineComponent } from 'vue'
import ChatBLTable from '@/views/chat-blacklist/table/ChatBLTable.vue'
import ChatBLCardHeader from '@/views/chat-blacklist/ChatBLCardHeader.vue'

export default defineComponent({
  components: { ChatBLCardHeader, ChatBLTable },
  async created() {
    await this.pageInit()
  },
  methods: {
    pageInit() {
      console.log('pageInit')
    },
  },
})
</script>

<style lang="scss" src="./index.scss"></style>
