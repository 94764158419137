<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0">
        <i class="fe fe-lock font-size-24 mr-1"/>{{ $t('common.chatBlacklist') }}
      </span>

  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>

<style lang="scss">

</style>
