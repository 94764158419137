<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <a-button type="primary" @click="openModalAddWord">
      {{ $t('chatBlacklist.addBtn') }}
      <a-icon type="plus"/>
    </a-button>
    <div class="" style="margin-left: auto;">
      <a-input-search
        :placeholder="$t('placeholders.selectSearch')"
        style="width: 300px"
        allowClear
        @change="changeInputValue"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    openModalAddWord() {
      this.$emit('openModalAddWord')
    },
    changeInputValue(value) {
      this.$emit('changeInputValue', value)
    },
  },
})
</script>

<style lang="scss">

</style>
